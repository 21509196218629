<template lang="pug">
    div.main-container(:style="{backgroundSize:'cover', backgroundImage:'url(' + require('@/assets/img2/insigne-bg-stroke-grey.png') + ')'} ")
        div.overlay
        div.search-overlay
        
        div.main-content(id="content")
            div.layout-px-spacing
                div.page-header
                    div.page-title
                        h3 {{ $t('teachhome.teadashboard') }}
                    
                    div
                        nav.breadcrumb-one(aria-label="breadcrumb")
                            ol.breadcrumb
                                li.breadcrumb-item
                                    a(href="javascript:void(0);") {{ $t('teachhome.teadashboard') }}
                                li.breadcrumb-item.active(aria-current="page") {{ $t('teachhome.dashboard') }}

                div.row.mt-5

                    router-link.infobox-3.widget.bg-white(href="#" to="/teacher/myGroups")
                        div
                            div.info-icon
                                svg.feather.feather-users(xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round")
                                    path(d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2")
                                    circle(cx="9" cy="7" r="4")
                                    path(d="M23 21v-2a4 4 0 0 0-3-3.87")
                                    path(d="M16 3.13a4 4 0 0 1 0 7.75")
                            h5.info-heading {{ $t('teachhome.mygroups') }}
                                   
                                  
                    router-link.infobox-3.widget.bg-white(href="#" to="/teacher/masiveLoad")
                        div
                            div.info-icon
                                svg(version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve")
                                    line(style="fill:none;stroke:#ffffff;stroke-width:1;stroke-linecap:round;stroke-linejoin:round;" x1="16.5" y1="9.4" x2="7.5" y2="4.2")
                                    path(style="fill:none;stroke:#ffffff;stroke-width:1;stroke-linecap:round;stroke-linejoin:round;" d="M21,16V8c0-0.7-0.4-1.4-1-1.7l-7-4c-0.6-0.4-1.4-0.4-2,0l-7,4C3.4,6.6,3,7.3,3,8v8c0,0.7,0.4,1.4,1,1.7l7,4c0.6,0.4,1.4,0.4,2,0l7-4C20.6,17.4,21,16.7,21,16z")
                                    polyline(style="fill:none;stroke:#ffffff;stroke-width:1;stroke-linecap:round;stroke-linejoin:round;" points="3.3,7 12,12 20.7,7 ")
                                    line(style="fill:none;stroke:#ffffff;stroke-width:1;stroke-linecap:round;stroke-linejoin:round;" x1="12" y1="22.1" x2="12" y2="12")
                                    polyline(style="fill:none;stroke:#ffffff;stroke-miterlimit:10;" points="19.4,2.5 21.6,0.4 23.6,2.4 ")
                                    line(style="fill:none;stroke:#ffffff;stroke-miterlimit:10;" x1="21.6" y1="0.4" x2="21.6" y2="4.5")
                            h5.info-heading {{ $t('teachhome.bulk') }}

                                    
                    router-link.infobox-3.widget.bg-white(href="#" to="/teacher/teacherRequestInventory")
                        div
                            div.info-icon
                                svg.feather.feather-clipboard(xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round")
                                    path(d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2")
                                    rect(x="8" y="2" width="8" height="4" rx="1" ry="1")
                            h5.info-heading {{ $t('teachhome.requestinv') }}
                                    
                    router-link.infobox-3.widget.bg-white(href="#" to="/teacher/groupReport")
                        div
                            div.info-icon
                                svg.feather.feather-pie-chart(xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round")
                                    path(d="M21.21 15.89A10 10 0 1 1 8 2.83")
                                    path(d="M22 12A10 10 0 0 0 12 2v10z")
                            h5.info-heading {{ $t('teachhome.myreports') }}
           
            div.footer-wrapper
                div.footer-section.ml-4
                    p © 2021 
                        a(target="_blank" href="https://etciberoamerica.com/") ETC Iberoamérica
                            template , {{ $t('teachhome.copy') }}
</template>

<script>
/* eslint-disable */
export default {
    name: 'Home',
    components: {
    },
    data() {
        return {
        }
    },
    methods: {
    },
    mounted() {
        this.checkPermisions(this.$router.currentRoute.path)
    }
}
</script>
